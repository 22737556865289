.speedtestFrame {
  overflow-x: hidden;
  overflow-Y: hidden;
}

.progressContainer {
  margin-left: auto;
  margin-right: auto;
  height: 0.8rem;
  width: 80%;
  border-radius: 0.4rem;
  background: #000;
}

.progressContainerProgress {
  height: 100%;
  width: 0;
  border-radius: 0.4rem;
  background: #ff4754;
  transition: width 0.4s ease;
}

.testPanelContainer {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  margin-top: 100px;
}

.statusLabel {
  margin: 15px;
}