.rowContainer {
  padding: 15px;
  background-color: #222;
  opacity: 0.9;
  width: 100%;
  color: #ddd;
  margin-bottom:10px;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
}

.locationTitle {
  font-weight: normal;
  font-size: 20px;
  flex-grow: 2;
  padding-top: 1px;
}

.locationSubtitle {

}

.locationIcon {
  width: 60px;
  padding-top: 4px;
  padding-left: 12px;
}

.cityTitle {
  width: 280px;
  text-align: right;
}

.rowContainer:hover {
  background-color: #333;
}

.premiumRowContainer {
  padding: 12px;
  border-radius:5px;
  background-color: rgb(20, 20, 20);
  opacity: 1;
  width: 100%;
  color: #aaa;
  margin-bottom:10px;
  display: flex;
  cursor: pointer;
}

.premiumRowContainer:hover {
  background-color: #333;
}

.columnContainer {
  align-self: center;
}

.featureContainer {
  display:flex;
}

@media (max-width: 575.98px) { 
  .rowContainer {
    font-size: 16px;
    padding: 10px;
  }
  .locationIcon {
    width: 40px;
    padding-left: 5px;
  }
  .locationTitle { 
    font-size: 13px;
    padding-top:0px;
  }
  .locationSubtitle {
    font-size: 12px;
    padding-left: 10px;
  }
  .cityTitle {
    width: auto;
    font-size: 12px;
  }
}