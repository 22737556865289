.scoreContainer {
  width: 100%;
}

.scoreContainer div {
  margin: 0px;
  width: 25px;
  height: 25px;
  border-radius:3px;
  display: inline-block;
  border:1px solid rgb(46, 31, 21);
}
