.locationTypeIcon {
  margin-top:25px;
  text-align: center;
}

.locationHeader {
  align-items: flex-start;
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.locationCity {
  width: 100%;
  font-size:16px;
  text-align: left;
  margin-bottom:50px;
  margin-top:-40px;
}

.websiteButton {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}

.speedtestContainer {
  display:flex;
  width: 250px;
  margin-top:20px;
  margin-bottom: 10px;
}

.speedtestReading {
  text-align: center;
  width: 120px;
  border:1px solid #333;
  border-radius: 5px;
  padding: 10px;  
  margin-right:10px;
}

.speedtestReadingNumber {
  color: rgb(19, 255, 19);
  font-family: 'Courier New', Courier, monospace;
  font-size: 30px;
}

.speedtestReadingUnit {
  font-size:12px;
  color: #888;
}

.speedtestReadingTitle {
  font-size:12px;
  color:white;
  margin-bottom:10px;
  font-weight: bold;
}

@media (max-width: 575.98px) { 
  .locationHeader {
    display: block;
    margin-top:-20px;
  }
  .locationTypeIcon {
    margin-top:-10px;
  }
  .locationTypeIcon svg, .locationTypeIcon br {
    display:none;
  }
  .locationCity {
    margin-top:20px;
    margin-bottom:10px;
  }
}