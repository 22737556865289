.csdb {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  fill: rgb(10, 10, 8);
  margin-top:50px;
}

.csdb svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 132px;
}

.csdb .shape-fill {
  fill: #ffffff;
}

.topImageContainer {
  margin-bottom: 0;
  margin-top: 0px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 0px;
  background-position-y: 0px;
}

.exploreTitle {
  font-size: 20px;
  color: white;
  margin-bottom: 40px;
}

@media (max-width: 575.98px) {
  .topImageContainer {
    margin-top: 0px;
    background-position-x: -130px;
  }
  .exploreTitle {
    font-size: 16px;
    margin-bottom: 0px;
  }
  .csdb {
    margin-top:5px;
  }
  .csdb svg {
    height: 80px;
  }
}
