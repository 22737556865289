.panel {
  border:1px solid #333;
  border-radius: 5px;
  width:180px;
  display: inline-block;
  padding:10px;
  text-align: center;
  margin-bottom:15px;
  margin-top:15px;
  cursor: pointer;
  user-select: none;
}

.title {
  margin-top:10px;
  font-size: 18px;
  margin-bottom: 5px;
  color: #fff;
}

.subtitle {
  font-size: 13px;
  color: #999;
}

.featureExists {
  opacity: 1.0;
}

.featureExists .title {
  color: white !important;
}

.featureNotDetected {
  opacity: 0.9;
  color: #999;
}

.panelGuestOverride, .panelGuestOverride .subtitle {
  display: block !important;
}

.feedbackContainer {
  display: block;
  margin-top:10px;
  justify-content: space-between;
}

.feedbackContainer button {
  width: 50%;
  margin-right:5px;
  margin-left:5px;
}

@media (max-width: 575.98px) { 
  .panel {
    width: 48%;
    margin-bottom:0px;
  }
  .title {
    font-size: 15px;
  }
  .subtitle {
    font-size: 11px;
  }
}