/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;  
  src: local(''),
       url('./fonts/lato-v22-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/lato-v22-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('./fonts/lato-v22-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/lato-v22-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('./fonts/lato-v22-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/lato-v22-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
       url('./fonts/lato-v22-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/lato-v22-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #aaa;
  background-color:rgb(14, 14, 15);
}

a {
  text-decoration: none;
  color:rgb(240, 145, 3);
}

* {
  box-sizing: border-box;
}

[contenteditable="true"]:focus {
  outline: none;
}

input:focus {
  outline-width: 0;
}

input {
  font-size: 18px;
  font-weight: 400;
  padding: 6px 7px;
  border-radius: 3px;
  border: 1px solid #eee;
}

select {
  font-size: 18px;
  font-weight: 400;
  padding: 6px 7px;
  border-radius: 3px;
  border: 1px solid #eee;
}


@media (max-width: 575.98px) { 
  h1 {
    font-size:20px;
  }
  h2 {
    font-size: 18px;
  }
}

.grecaptcha-badge { visibility: hidden; }