.typewriter {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  color: white;
  margin-top:100px;
  margin-bottom:20px;
  /* background-color:black; */
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
}

.gettingStarted {
  color: white;
  text-shadow: 0px 0px 5px #000;
  text-align: center;
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
}

.inputForm {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputField {
  text-align: center;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
  color: black;
  font-size: 18px;
  font-weight: bold;
  border: 0px solid #777;
  border-radius: 20px;
  height: 45px;
  width: 200px;
}

.submitButton {
  margin-left: 10px;
  position: relative;
  top: 0px;
  height: 42px;
  color: white;
  padding-bottom: 9px;
  padding-top: 11px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 13px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  background-color: rgb(247, 97, 11);
  cursor: pointer;
}

.searchSuggestionContainer {
  padding: 20px;
  background-color: rgba(17, 17, 17, 1);
  box-shadow: 5px 5px 15px #111;
  margin-bottom: 40px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  border-radius: 10px;
}

.searchSuggestionRow {
  background-color: rgba(31, 29, 28, 0.6);
  border-radius: 10px;
  text-shadow: 0px 0px 5px #000;
  padding: 10px;
  cursor: pointer;
  color: white;
  margin-bottom: 3px;
}

.searchSuggestionRow:hover {
  background-color: rgba(31, 29, 28, 1);
}

.explainer {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  padding: 30px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 0px;
  margin-top: 100px;
  border-radius: 10px;
  color: #c9c9c9;
  line-height: 1.5em;
  /*  box-shadow: 0px 0px 35px #111; */
}

.explainerTitle {
  font-weight: lighter;
  color:#ff8011;
  font-size: 40px;
  margin-bottom: 20px;
}

.explainerSubtitle2 {
  font-size: 22px;
  padding-top: 20px;
  text-align: center;
}

.categoryRadios {
  display: flex;
  justify-content: space-between;
  width: 600px;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:10px;
  margin-top: 40px;
}

.categoryRadios input {
  transform: scale(1.8);
  margin-right: 13px;
}

.categorySelectorWrapper {
  position: relative;
}

.categorySelectorWrapper::before {
  content: "▼";
  font-size: 12px;
  position: absolute;
  text-shadow: none;
  right: 20px;
  top: 15px;
  color: #999;
  pointer-events: none;
}

.categorySelector {
  appearance: none;
  border: none;  
  margin-right: 10px;
  border-radius: 20px;
  padding: 10px;
}

.categorySelector option {
  font-size: 18px;
}

@media (max-width: 575.98px) {
  .homeHero {
    width: 100%;
  }

  .typewriter {
    background-color: #000000aa;
    padding: 10px;
    margin-top: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
    border-radius: 0px;
  }

  .gettingStarted {
    width: 100%;
  }

  .inputForm {
    display:flex;
    flex-direction: column;
  }

  .inputForm > span {
    margin-right:0px !important;
    margin-left:0px !important;
    margin-bottom:10px;
    font-size: 13px;
  }

  .inputField {
    width: 180px;
    margin-bottom:10px;
  }

  .categorySelector {
    margin-right: 0px;
    margin-bottom:10px;
  }


  .explainer {
    border-radius: 0;
    margin-top:0px;
    font-size: 18px;
  }
  .explainerTitle {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.2em;
    padding-left:20px;
    padding-right:20px;
  }
  .explainerSubtitle2 {
    display: none;
  }  
}
