.mainContainer {
}

.noMobile {
  display: none;
}

.container {
  min-height: 100vh;
}

.footer {
  text-align: center;
  margin-top: 50px;
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #555;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

@media (max-width: 575.98px) {
  .noMobile {
    font-size: 11px;
    text-align: center;
    display: block;
    padding: 10px;
    margin-top: 60px;
  }
  .mainContainer {
    padding-top: 0px !important;
  }
}
