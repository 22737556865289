.tile {
  width: 200px;
  margin: 20px;
  cursor: pointer;
  transition-duration: 300;
  transition-timing-function: ease-in-out;  
}

.tileImage {
  background-size: cover;
  background-position: center center;
  width: 200px;
  height: 200px;
  border-radius: 200px;
}

.tile:hover {
  opacity: 0.7;
}

.tileTitle {
  padding: 2px;
  text-align: center;
  margin-top:10px;
}

@media (max-width: 575.98px) { 
  .tile {
    width: 120px;
  }
  .tileImage {
    width: 120px;
    height: 120px;
  }
}