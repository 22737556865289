.tileContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media (max-width: 575.98px) { 
  .tileContainer {
    padding:20px;
  }
}