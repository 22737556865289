.separator {
  border-bottom:1px solid #444;
  padding-top: 30px;
  margin-bottom:30px;
}

.identifiedLocation {
  border:1px solid #444;
  padding: 10px;
  display:inline-block;
  color: white;
  margin-bottom:10px;
  margin-top: 10px;
  border-radius: 10px;
}