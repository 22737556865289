.resultRow {
  background-color: #111;
  margin-bottom: 2px;
  padding:6px 10px;
  font-size:14px;
  cursor: pointer;
  border-radius: 5px;
}

.resultRow:hover {
  background-color: #444;
  color:orange;
}

.resultPanel {
  z-index: 100;
  padding:20px;
  background-color: #111;
  border:1px solid #222;
  border-radius: 15px;
  position: absolute;
  margin-top:-20px;
}

.resultPicked {
  background-color:#222;
  padding: 10px 15px;
  border-radius: 5px;
}

.resultPickedPanel {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.clearButton {
  margin-left: 10px;
  font-size: 25px;
  cursor: pointer;
}