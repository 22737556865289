.panel {
  margin-top:20px;
  margin-bottom:40px;
  width: 100%;
}

.text {
  border-radius: 10px;
  padding: 10px;
  width: 600px;
  height: 90px;
  font-size: 15px;
  border: none;
  background-color:#222;
  color: rgb(255, 159, 50);
}