.scorePanel {
  text-align: center;
  padding:30px;
  padding-top:15px;
  border-radius:10px;
  margin-top:50px;
  width: 300px;
  margin-left:auto;
  margin-right:auto;
  background-color:#050505;
  box-shadow: 0px 0px 15px #000;
}

.scorePanelNumber {
  font-size:80px;
  font-weight: bold;
  color: rgb(240, 116, 0);
}

.scorePanelTitle {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  color: #666;
}