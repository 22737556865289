.categoryFilters {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

@media (max-width: 575.98px) { 
  .categoryFilters {
    flex-wrap: wrap;
  } 
}