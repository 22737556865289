.main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    width: 100%;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;  
  }
  
.mainFullWidth {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    width: 100%;
    margin-left: auto;
    margin-right: auto;  
  }

@media (max-width: 575.98px) { 
  .main {
    padding: 20px;
  }
  .mainFullWidth {
    padding: 0px;
  }
  .homeHeroHost {
    padding: 0px;
  }
}