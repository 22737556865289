.primaryButton {
  color: white;
  padding:10px;
  padding-left:18px;
  padding-right:18px;
  font-size:15px;
  text-shadow: 0px 0px 2px rgba(0,0,0,0.4);
  border:none;
  border-radius:5px;
  background-color: rgb(211 84 0);
  cursor: pointer;
}

.primaryButton:hover {
  background-color: rgb(255, 142, 67);
  box-shadow: 0px 0px 14px rgba(255,255,255,0.1);
}

.secondaryButton {
  color: #ccc;
  padding:10px;
  padding-left:18px;
  padding-right:18px;
  font-size:13px;
  border:1px solid rgb(50, 56, 82);
  border-radius:10px;
  background-color: #1C232B;
  cursor: pointer;
  text-shadow: 0px 0px 3px rgba(0,0,0,0.2);  
}

.secondaryButton:hover {
  background-color: #28323d;
  color: white;
}

.disabledButton {
  opacity: 0.5;
}

.largeButton {
  font-size:18px;
  font-weight: 400;
  padding: 12px 20px;
}

.smallButton {
  font-size:12px;
  padding: 5px 10px;
  border-radius: 20px;
}