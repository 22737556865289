.hamburger {
  padding: 15px 15px;
  padding-right:0px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  margin-right:-10px;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.isActive:hover {
    opacity: 0.7; }
  .hamburger.isActive .hamburgerInner,
  .hamburger.isActive .hamburgerInner::before,
  .hamburger.isActive .hamburgerInner::after {
    background-color: white; }

.hamburgerBox {
  width: 40px;
  height: 20px;
  display: inline-block;
  position: relative; 
}

.hamburgerInner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburgerInner, .hamburgerInner::before, .hamburgerInner::after {
    width: 30px;
    height: 2px;
    background-color: white;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburgerInner::before, .hamburgerInner::after {
    content: "";
    display: block; }
  .hamburgerInner::before {
    top: -10px; }
  .hamburgerInner::after {
    bottom: -10px; }

.hamburgerElastic .hamburgerInner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburgerElastic .hamburgerInner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburgerElastic .hamburgerInner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburgerElastic.isActive .hamburgerInner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s; }
  .hamburgerElastic.isActive .hamburgerInner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburgerElastic.isActive .hamburgerInner::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s; }