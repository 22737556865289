.buttonRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #222;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
}

.buttonLeft {
  display: flex;
  align-items: center;
  background-color: #222;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
}

.buttonRightLabel {
  margin-right: 20px;
}

.buttonLeftLabel {
  margin-left: 20px;
}

@media (max-width: 575.98px) {  
  .buttonRight, .buttonLeft {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 1.4em;
  }
  .buttonLeft {
    flex-direction: column-reverse;
  }
  .buttonRight button, .buttonLeft button {
    margin-top: 20px;
  }
  .buttonRightLabel, .buttonLeftLabel {
    margin: 0;
  }

}