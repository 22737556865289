.rowContainer {
  padding: 15px;
  background-color: #222;
  opacity: 0.9;
  width: 30%;
  color: #ddd;
  margin-bottom:10px;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
}

.rowContainer:hover {
  background-color: #333;
}