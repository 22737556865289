.desktopMenu {
  display: flex;
}

.mobileMenu {
  display: none;
}

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}

.navContainer {
  left: 0px;
  height: 60px;
  background-color: #1d232b;
  box-sizing: border-box;
  padding:5px;
  position: absolute;
  top:0px;
  width: 100%;
  box-sizing: border-box;
  background-color: #202020;
}

.navContainer a {
  color: rgb(223, 228, 240);
  font-size: 14px;
  font-weight: light;
  opacity: 0.8;

  margin-top:13px;
  margin-left: 10px;

  height: 27px;
  padding-bottom:5px;
  padding-top:5px;
  padding-left:15px;
  padding-right:15px;
  border-radius:30px;

  transition: 0.5s;
}

.navContainer a:hover {
  opacity: 1;
  color:#000;
  background-color: rgba(255,255,255,1);
  box-shadow: 0px 0px 4px rgba(255,255,255,0.3);
}

.navBar {
  width: 100%;
  max-width: 1024px;
  margin-left:auto;
  margin-right:auto;
}

.linkContainer {
  display: flex;
  justify-content: space-between;
}

.logo {
  position: relative;
  top: 15px;
  left: 0px;
  cursor: pointer;
}

.spacer {
  flex-grow: 1;
}

@media (max-width: 575.98px) { 

  .desktopMenu {
    display: none;
  }

  .mobileMenu {
    display: block;
  }

  .mobileMenuPanel {
    position: absolute;
    left: 0px;
    right: 0px;
    width: 100%;
    background:black;
    padding:25px;
    display:block;
    min-height: 200px;
    z-index: 10000;
    box-shadow: 0px 30px 30px #1d232b;
    animation: fadeIn 0.5s;
  }

  .mobileMenuItem {
    padding-top: 15px;
    padding-bottom:15px;
    border-bottom: 1px dotted #333;
  }

  .mobileMenuItem:last-child {
    border-bottom: none;
  }

  .mobileMenuItem a {
    font-size: 16px;
  }

  .navContainer, .navBar, .linkContainer {
    background-color: black;
  }
  .linkContainer {
    padding-right:20px;
  }
  .logo {
    margin-left:20px;
  }
}