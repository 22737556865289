.mapContainer {
  height: 400px;
  width: 100%;
  border-radius: 10px;
}

@media (max-width: 575.98px) {
  .mapContainer {
    height: 200px;
  }
}