.tabItem {
  padding:10px;
  border-bottom: 3px solid #333;
  cursor: pointer;
  margin-right: 10px;
}

.tabItem:hover, .tabItemActive {
  border-bottom: 5px solid rgb(243, 106, 26);
}
