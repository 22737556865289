.featureToggle {
  color: white;
  padding:10px;
  padding-left:18px;
  padding-right:18px;
  font-size:14px;
  border:none;
  border-radius:20px;
  cursor: pointer;
  text-shadow: 0px 0px 3px rgba(0,0,0,0.2);
  max-width: 200px;
  display: inline-block;
  background-color: #333;
  margin-right: 10px;
  margin-bottom:10px;
  user-select: none;
}

.featureToggle:hover {
  background-color: rgb(248, 111, 19);
  box-shadow: 0px 0px 14px rgba(255,255,255,0.1);
}

.toggleOn {
  background-color: rgb(33, 67, 119);
}


@media (max-width: 575.98px) { 
  
}