.header {
  color: white;
  text-align: left;
  margin-bottom: 50px;
  width: 100%;
}

.subline {
  border-bottom: 1px dashed #666;
  padding-bottom: 20px;
}

.subHeader {
  color: white;
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
}

.heart {
  display: inline-block;
  cursor: pointer;
  margin-left:10px;
}

.heart:hover svg {
  filter: drop-shadow( 0px 0px 8px rgba(190, 0, 0, 0.7));
}

@media (max-width: 575.98px) {
  .header {
    margin-bottom: 20px;
    text-align: center !important;
  }
  .subHeader {
    font-size: 16px;
    font-weight: normal;
  }
}

